<template>
  <div>
    <b-row v-if="elementData">
      <b-overlay
        v-if="!meetUuid"
        :show="submitting"
        no-wrap
        fixed
        rounded="sm">
        <template
          id="template_overlay"
          #overlay>
          <div class="d-flex align-items-center" />
          <b-spinner
            small
            type="grow"
            variant="secondary" />
          <b-spinner
            type="grow"
            variant="dark" />
          <b-spinner
            small
            type="grow"
            variant="secondary" />
          <div class="d-flex align-items-center" />
        </template>
      </b-overlay>

      <b-col
        sm="12"
        :md="meetUuid ? 12 : 6"
        :lg="meetUuid ? 12 : 9">
        <vue-good-wizard
          ref="wizard"
          :steps="steps"
          :on-next="nextClicked"
          :on-back="backClicked"
          previous-step-label="Anterior"
          next-step-label="Siguiente"
          :final-step-label="getFinishButtonLabel()">
          <div slot="page1">
            <first-identification-card
              v-if="
                elementData.id &&
                caseDate &&
                caseDate.id &&
                consultStatusList.length > 0 &&
                elementData.patient &&
                (!this.elementData.patient.avatar_id ||
                  elementData.patient.avatar !== undefined)
              "
              ref="page1"
              :element-data="elementData"
              :case-date="caseDate"
              :document-type-list="documentTypeList"
              :contact-city-list="contactCityList"
              :contact-department-list="contactDepartmentList"
              :company-city-list="companyCityList"
              :company-department-list="companyDepartmentList"
              :contact-type-list="contactTypeList"
              :consult-type-list="consultTypeList"
              :consult-status-list="consultStatusList"
              :consult-case-type-list="consultCaseTypeList"
              :patient-scholarship-list="patientScholarshipList"
              :patient-civil-status-list="patientCivilStatusList"
              :patient-type-list="patientTypeList"
              :relationship-list="relationshipList"
              :population-zone-list="populationZoneList"
              :doctor-list="doctorList"
              :edition-time="editionTime"
              :current-consult-status="currentConsultStatus"
              :disabled="disabled" />
            <h4>Paso 1</h4>
            <p>Identificación</p>
          </div>
          <div
            v-if="case_type == 'HEPA'"
            slot="page2">
            <second-accident-information-card
              v-if="elementData"
              ref="page2"
              :element-data="elementData"
              :work-shift-list="workShiftList"
              :job-held-list="jobHeldList"
              :job-area-list="jobAreaList"
              :accident-place-list="accidentPlaceList"
              :accident-type-list="accidentTypeList"
              :lesion-depth-list="lesionDepthList"
              :skin-condition-list="skinConditionList"
              :blood-amount-list="bloodAmountList"
              :body-part-list="bodyPartList"
              :injury-agent-list="injuryAgentList"
              :injury-time-list="injuryTimeList"
              :compromised-liquid-list="compromisedLiquidList"
              :ecp-purpose-list="ecpPurposeList"
              :boolean-n-a-list="booleanNAList"
              :disabled="disabled" />
            <h4>Paso 2</h4>
            <p>Características del Accidente</p>
          </div>
          <div
            v-if="case_type == 'HEPA'"
            slot="page3">
            <third-source-information-card
              v-if="elementData"
              ref="page3"
              :element-data="elementData"
              :document-type-list="documentTypeList"
              :boolean-n-a-list="booleanNAList"
              :boolean-list="booleanList"
              :disabled="disabled" />
            <h4>Paso 3</h4>
            <p>Información de la Fuente</p>
          </div>
          <div
            v-if="case_type == 'HEPA'"
            slot="page4">
            <fourth-immune-status-card
              v-if="elementData"
              ref="page4"
              :element-data="elementData"
              :boolean-dnna-list="booleanDnnaList"
              :boolean-dn-list="booleanDnList"
              :boolean-dnnau-list="booleanDnnauList"
              :dose-quantity-list="doseQuantityList"
              :applied-scheme-list="appliedSchemeList"
              :other-background-list="otherBackgroundList"
              :disabled="disabled" />
            <h4>Paso 4</h4>
            <p>Estado inmunológico</p>
          </div>
          <div
            v-if="case_type == 'HEPA'"
            slot="page5">
            <fourth-and-half-lab-test-card
              v-if="elementData"
              ref="page5"
              :element-data="elementData"
              :boolean-list="booleanList"
              :disabled="disabled" />
            <h4>Paso 5</h4>
            <p>Exámenes Médicos</p>
          </div>
          <div
            v-if="case_type == 'HEPA'"
            slot="page6">
            <fifth-action-plan-card
              v-if="elementData"
              ref="page6"
              :element-data="elementData"
              :antiretroviral-list="antiretroviralList"
              :medicines-list="medicinesList"
              :medical-follow-vih-list="medicalFollowVihList"
              :medical-follow-vhc-list="medicalFollowVhcList"
              :medical-follow-syphilis-list="medicalFollowSyphilisList"
              :origin-classification-pat-list="originClassificationPatList"
              :vaccine-hepatitis-b-list="vaccineHepatitisBList"
              :medical-follow-list="medicalFollowList"
              :severity-list="severityList"
              :disabled="disabled" />
            <h4>Paso 6</h4>
            <p>Plan de Acción</p>
          </div>
          <div
            v-if="case_type == 'HEPA'"
            slot="page7">
            <six-recommendations-card
              ref="page7"
              :element-data="elementData"
              :diagnosis-list="diagnosisList"
              :consult-case-status-list="consultCaseStatusList"
              :severity-list="severityList"
              :principal-diagnosis-type-list="principalDiagnosisTypeList"
              :antobodies-status-list="antobodiesStatusList"
              :disabled="disabled" />
            <h4>Paso 7</h4>
            <p>Recomendaciones</p>
          </div>
          <div
            v-if="case_type == 'HEPA'"
            slot="page8">
            <seven-medical-orders-card
              ref="page8"
              :element-data="elementData"
              :diagnosis-list="diagnosisList"
              :destination-orders-list="destinationOrdersList"
              :clinical-laboratory-city-list="clinicalLaboratoryCityList"
              :clinical-laboratory-department-list="
                clinicalLaboratoryDepartmentList
              "
              :clinical-headquarter-list="clinicalHeadquarterList"
              :clinical-laboratory-list="clinicalLaboratoryList"
              :clinical-laboratory-services-list="
                clinicalLaboratoryServicesList
              "
              :medicines-list="rootMedicinesList"
              :vaccine-city-list="vaccineCityList"
              :vaccine-department-list="vaccineDepartmentList"
              :vaccine-headquarter-list="vaccineHeadquarterList"
              :vaccine-laboratory-list="vaccineLaboratoryList"
              :vaccine-services-list="vaccineServicesList"
              :service-type-list="serviceTypeList"
              :case-tracking-type="caseTrackingType"
              :medical-consult-services-list="medicalConsultServicesList"
              :case-type="case_type"
              :boolean-list="booleanList"
              :disabled="disabled"
              :required-headquarter="requiredHeadquarter"
              :required-vaccine-headquarter="requiredVaccineHeadquarter"
              :principal-diagnosis-type-list="principalDiagnosisTypeList" />
            <h4>Paso 8</h4>
            <p>Órdenes Médicas</p>
          </div>
          <div
            v-if="case_type == 'HEPA'"
            slot="page9">
            <eight-medical-certifications-card
              v-if="current_certification"
              ref="page9"
              :element-data="current_certification"
              :certification-type-list="certificationTypeList"
              :boolean-list="booleanList"
              :disabled="disabled"
              :origin-classification-list="originClassificationPatList"
              :element-data-full="elementData" />
            <h4>Paso 9</h4>
            <p>Certificaciones Médicas</p>
          </div>

          <div
            v-if="case_type == 'COVID'"
            slot="page2Covid">
            <second-covid-accident-information-card
              v-if="elementData"
              ref="page2Covid"
              :element-data="elementData"
              :job-held-list="jobHeldList"
              :boolean-list="booleanList"
              :disabled="disabled" />
            <h4>Paso 2</h4>
            <p>Características del Accidente</p>
          </div>
          <div
            v-if="case_type == 'COVID'"
            slot="page3Covid">
            <third-covid-action-plan-card
              ref="page3Covid"
              :element-data="elementData"
              :diagnosis-list="diagnosisList"
              :origin-classification-list="originClassificationList"
              :contact-type-list="consultContactTypeList"
              :contact-diagnosis-list="contactDiagnosisList"
              :patient-status-list="patientStatusList"
              :case-status-list="consultCaseStatusList"
              :disabled="disabled" />
            <h4>Paso 3</h4>
            <p>Plan de acción</p>
          </div>
          <div
            v-if="case_type == 'COVID'"
            slot="page4Covid">
            <seven-medical-orders-card
              ref="page4Covid"
              :element-data="elementData"
              :diagnosis-list="diagnosisList"
              :destination-orders-list="destinationOrdersList"
              :clinical-laboratory-city-list="clinicalLaboratoryCityList"
              :clinical-laboratory-department-list="
                clinicalLaboratoryDepartmentList
              "
              :clinical-headquarter-list="clinicalHeadquarterList"
              :clinical-laboratory-list="clinicalLaboratoryList"
              :clinical-laboratory-services-list="
                clinicalLaboratoryServicesList
              "
              :medicines-list="rootMedicinesList"
              :vaccine-city-list="vaccineCityList"
              :vaccine-department-list="vaccineDepartmentList"
              :vaccine-headquarter-list="vaccineHeadquarterList"
              :vaccine-laboratory-list="vaccineLaboratoryList"
              :vaccine-services-list="vaccineServicesList"
              :service-type-list="serviceTypeList"
              :case-tracking-type="caseTrackingType"
              :medical-consult-services-list="medicalConsultServicesList"
              :case-type="case_type"
              :boolean-list="booleanList"
              :disabled="disabled"
              :required-headquarter="requiredHeadquarter"
              :required-vaccine-headquarter="requiredVaccineHeadquarter"
              :principal-diagnosis-type-list="principalDiagnosisTypeList" />
            <h4>Paso 4</h4>
            <p>Órdenes Médicas</p>
          </div>
          <div
            v-if="case_type == 'COVID'"
            slot="page5Covid">
            <eight-medical-certifications-card
              v-if="current_certification"
              ref="page5Covid"
              :element-data="current_certification"
              :certification-type-list="certificationTypeList"
              :boolean-list="booleanList"
              :disabled="disabled"
              :origin-classification-list="originClassificationList"
              :element-data-full="elementData" />
            <h4>Paso 5</h4>
            <p>Certificaciones Médicas</p>
          </div>

          <div
            v-if="case_type == 'OTHER'"
            slot="page2Other">
            <second-other-accident-information-card
              v-if="elementData"
              ref="page2Other"
              :element-data="elementData"
              :job-held-list="jobHeldList"
              :disabled="disabled" />
            <h4>Paso 2</h4>
            <p>Características del Accidente</p>
          </div>
          <div
            v-if="case_type == 'OTHER'"
            slot="page3Other">
            <third-other-action-plan-card
              ref="page3Other"
              :element-data="elementData"
              :diagnosis-list="diagnosisList"
              :origin-classification-list="originClassificationList"
              :case-status-list="consultCaseStatusList"
              :boolean-list="booleanList"
              :disabled="disabled" />
            <h4>Paso 3</h4>
            <p>Plan de acción</p>
          </div>
          <div
            v-if="case_type == 'OTHER'"
            slot="page4Other">
            <seven-medical-orders-card
              ref="page4Other"
              :element-data="elementData"
              :diagnosis-list="diagnosisList"
              :destination-orders-list="destinationOrdersList"
              :clinical-laboratory-city-list="clinicalLaboratoryCityList"
              :clinical-laboratory-department-list="
                clinicalLaboratoryDepartmentList
              "
              :clinical-headquarter-list="clinicalHeadquarterList"
              :clinical-laboratory-list="clinicalLaboratoryList"
              :clinical-laboratory-services-list="
                clinicalLaboratoryServicesList
              "
              :medicines-list="rootMedicinesList"
              :vaccine-city-list="vaccineCityList"
              :vaccine-department-list="vaccineDepartmentList"
              :vaccine-headquarter-list="vaccineHeadquarterList"
              :vaccine-laboratory-list="vaccineLaboratoryList"
              :vaccine-services-list="vaccineServicesList"
              :service-type-list="serviceTypeList"
              :case-tracking-type="caseTrackingType"
              :medical-consult-services-list="medicalConsultServicesList"
              :case-type="case_type"
              :boolean-list="booleanList"
              :disabled="disabled"
              :required-headquarter="requiredHeadquarter"
              :required-vaccine-headquarter="requiredVaccineHeadquarter"
              :principal-diagnosis-type-list="principalDiagnosisTypeList" />
            <h4>Paso 4</h4>
            <p>Órdenes Médicas</p>
          </div>
          <div
            v-if="case_type == 'OTHER'"
            slot="page5Other">
            <eight-medical-certifications-card
              v-if="current_certification"
              ref="page5Other"
              :element-data="current_certification"
              :certification-type-list="certificationTypeList"
              :boolean-list="booleanList"
              :disabled="disabled"
              :origin-classification-list="originClassificationList"
              :element-data-full="elementData" />
            <h4>Paso 5</h4>
            <p>Certificaciones Médicas</p>
          </div>
        </vue-good-wizard>
      </b-col>
      <b-col
        v-if="!meetUuid"
        sm="12"
        md="6"
        lg="3"
        class="float">
        <meet :options="computedJitsiOptions" />
      </b-col>
    </b-row>
    <div v-if="!meetUuid">
      <b-modal
        id="modal-save-confirmation"
        hide-footer
        :title="$t('actions.save')">
        <template>
          <save-confirmation
            :resources-element-name="RESOURCES_ELEMENT_NAME"
            class="mt-2 pt-75" />
        </template>
      </b-modal>
    </div>
    <div v-if="!meetUuid">
      <b-modal
        id="modal-plan-confirmation"
        hide-footer
        :title="$t('actions.confirmation')">
        <template>
          <save-confirmation
            :resources-element-name="RESOURCES_ELEMENT_NAME"
            modal-id="modal-plan-confirmation"
            :body-text="$t('consult.object.save_plan_confirmation')"
            class="mt-2 pt-75" />
        </template>
      </b-modal>
    </div>
  </div>
</template>

<script>
import {
  BOverlay,
  BSpinner,
  BRow,
  BCol,
} from 'bootstrap-vue'
import router from '@/router'
import store from '@/store'
import { mapState } from 'vuex'
import { GoodWizard } from 'vue-good-wizard'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import SaveConfirmation from '@/views/core/SaveConfirmation.vue'
import moment from 'moment'
import Meet from './Meet.vue'
import elementStoreModule from '../storeModule'
import FirstIdentificationCard from './FirstIdentificationCard.vue'
import SecondAccidentInformationCard from './SecondAccidentInformationCard.vue'
import ThirdSourceInformationCard from './ThirdSourceInformationCard.vue'
import FourthImmuneStatusCard from './FourthImmuneStatusCard.vue'
import FifthActionPlanCard from './FifthActionPlanCard.vue'
import SixRecommendationsCard from './SixRecommendationsCard.vue'
import SevenMedicalOrdersCard from './SevenMedicalOrdersCard.vue'
import EightMedicalCertificationsCard from './EightMedicalCertificationsCard.vue'
import ThirdCovidActionPlanCard from './ThirdCovidActionPlanCard.vue'
import ThirdOtherActionPlanCard from './ThirdOtherActionPlanCard.vue'
import FourthAndHalfLabTestCard from './FourthAndHalfLabTestCard.vue'

import SecondCovidAccidentInformationCard from './SecondCovidAccidentInformationCard.vue'
import SecondOtherAccidentInformationCard from './SecondOtherAccidentInformationCard.vue'

export default {
  components: {
    SaveConfirmation,
    BSpinner,
    BOverlay,
    BRow,
    BCol,
    Meet,
    'vue-good-wizard': GoodWizard,
    FirstIdentificationCard,
    SecondAccidentInformationCard,
    ThirdSourceInformationCard,
    FourthImmuneStatusCard,
    FifthActionPlanCard,
    SixRecommendationsCard,
    SevenMedicalOrdersCard,
    EightMedicalCertificationsCard,
    FourthAndHalfLabTestCard,

    SecondCovidAccidentInformationCard,
    ThirdCovidActionPlanCard,

    SecondOtherAccidentInformationCard,
    ThirdOtherActionPlanCard,
  },
  props: {
    meetUuid: {
      type: String,
      default: null,
      required: false,
    },
    edition_disabled: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data() {
    return {
      disabled: false,
      submitting: false,
      currentPage: 0,
      elementData: undefined,
      caseDate: undefined,
      ELEMENT_APP_STORE_MODULE_NAME: '',
      RESOURCES_ELEMENT_NAME: '',
      uuid: null,
      not_attended: false,
      contactCityList: [],
      contactDepartmentList: [],
      companyCityList: [],
      companyDepartmentList: [],
      contactTypeList: [],
      consultTypeList: [],
      consultStatusList: [],
      consultCaseTypeList: [],
      patientScholarshipList: [],
      patientCivilStatusList: [],
      patientTypeList: [],
      workShiftList: [],
      jobHeldList: [],
      jobAreaList: [],
      accidentPlaceList: [],
      accidentTypeList: [],
      lesionDepthList: [],
      skinConditionList: [],
      bloodAmountList: [],
      bodyPartList: [],
      injuryAgentList: [],
      injuryTimeList: [],
      compromisedLiquidList: [],
      ecpPurposeList: [],
      booleanNAList: [],
      booleanList: [],
      booleanDnnaList: [],
      booleanDnList: [],
      booleanDnnauList: [],
      otherBackgroundList: [],
      doseQuantityList: [],
      appliedSchemeList: [],
      documentTypeList: [],
      medicinesList: [],
      antiretroviralList: [],
      medicalFollowVihList: [],
      medicalFollowVhcList: [],
      medicalFollowSyphilisList: [],
      vaccineHepatitisBList: [],
      medicalFollowList: [],
      severityList: [],
      diagnosisList: [],
      principalDiagnosisTypeList: [],
      consultCaseStatusList: [],
      biologicalDangerList: [],
      antobodiesStatusList: [],
      destinationOrdersList: [],
      clinicalLaboratoryCityList: [],
      clinicalLaboratoryDepartmentList: [],
      clinicalHeadquarterList: [],
      clinicalLaboratoryList: [],
      clinicalLaboratoryServicesList: [],
      rootMedicinesList: [],
      vaccineCityList: [],
      vaccineDepartmentList: [],
      vaccineHeadquarterList: [],
      vaccineLaboratoryList: [],
      vaccineServicesList: [],
      certificationTypeList: [],
      originClassificationList: [],
      originClassificationPatList: [],
      consultContactTypeList: [],
      contactDiagnosisList: [],
      patientStatusList: [],
      serviceTypeList: [],
      populationZoneList: [],
      relationshipList: [],
      caseTrackingType: [],
      medicalConsultServicesList: [],
      doctorList: [],
      editionTime: undefined,
      current_certification: undefined,
      is_last_page: false,
      is_action_plan: false,
      final_consult_date: null,
      currentConsultStatus: '',
      steps: [],
      steps_covid: [
        {
          label: 'Identificación',
          slot: 'page1',
        },
        {
          label: 'Características del accidente',
          slot: 'page2Covid',
        },
        {
          label: 'Plan de acción',
          slot: 'page3Covid',
        },
        {
          label: 'Órdenes Médicas',
          slot: 'page4Covid',
          medicalOrder: true,
        },
        {
          label: 'Certificados Médicos',
          slot: 'page5Covid',
          final: true,
        },
      ],
      steps_other: [
        {
          label: 'Identificación',
          slot: 'page1',
        },
        {
          label: 'Características del accidente',
          slot: 'page2Other',
        },
        {
          label: 'Plan de acción',
          slot: 'page3Other',
        },
        {
          label: 'Órdenes Médicas',
          slot: 'page4Other',
          medicalOrder: true,
        },
        {
          label: 'Certificados Médicos',
          slot: 'page5Other',
          final: true,
        },
      ],

      case_type: '',
      steps_not_attended: [
        {
          label: 'Identificación',
          slot: 'page1',
          final: true,
          temporal: true,
        },
      ],

      steps_hepa: [
        {
          label: 'Identificación',
          slot: 'page1',
        },
        {
          label: 'Características del accidente',
          slot: 'page2',
        },
        {
          label: 'Información de la fuente',
          slot: 'page3',
        },
        {
          label: 'Estado inmunológico',
          slot: 'page4',
        },
        {
          label: 'Exámenes médicos',
          slot: 'page5',
        },
        {
          label: 'Plan de acción',
          slot: 'page6',
        },
        {
          label: 'Recomendaciones',
          slot: 'page7',
        },
        {
          label: 'Órdenes Médicas',
          slot: 'page8',
          medicalOrder: true,
        },
        {
          label: 'Certificaciones Médicas',
          slot: 'page9',
          final: true,
        },
      ],
      requiredHeadquarter: false,
      requiredVaccineHeadquarter: false,
    }
  },
  computed: {
    ...mapState('dataList', ['getDataList']),
    computedJitsiOptions() {
      if (!this.uuid) {
        // UUID not available, return empty options
        return {}
      }
      // UUID available, generate Jitsi Meet options
      return {
        roomName: `avenir/${this.uuid}`,
        noSSL: false,
        userInfo: {
          email: 'user@email.com', // TODO: get from user
          displayName: JSON.parse(localStorage.getItem('userData')).fullName,
        },
        height: '600px',
        width: '100%',
      }
    },
  },
  watch: {
    currentPage(val) {
      window.scrollTo(0, 0)
    },
  },
  beforeDestroy() {
    this.$root.$off('save-element')
    this.$root.$off('change-case-type')
    this.$root.$off('save-edition-time')
    this.$root.$off('fetch-medicines')
    this.$root.$off('fetch-cities')
    this.$root.$off('fetch-company-cities')
    this.$root.$off('fetch-clinical-cities')
    this.$root.$off('fetch-vaccine-cities')
    this.$root.$off('fetch-clinical-laboratory-headquarters')
    this.$root.$off('filter-clinical-headquarters-by-city')
    this.$root.$off('filter-clinical-headquarters-by-department')
    this.$root.$off('fetch-vaccine-headquarters')
    this.$root.$off('filter-vaccine-headquarters-by-city')
    this.$root.$off('filter-vaccine-headquarters-by-department')
  },
  async mounted() {
    this.submitting = true
    this.RESOURCES_ELEMENT_NAME = 'consult'
    this.ELEMENT_APP_STORE_MODULE_NAME = `app-m-c-loc-${this.RESOURCES_ELEMENT_NAME}`
    const _self = this

    await this.$store
      .dispatch('dataList/fetch_lists', { _country: 'Colombia' })
      .then()
      .catch((error) => {
        console.error('Error fetching data:', error)
      })

    _self.diagnosisList = await this.$store.getters['dataList/getDataList']({
      listState: 'diagnosis',
      messageDefault: 'SELECT_CODE',
    })
    _self.diagnosisList.forEach((item) => {
      if (item.id === null || item.id === -1) {
        item.key = 'Código'
      }
    })

    const dataStore_Select = await this.$store.getters['dataList/getDataList']({
      listState: [
        'certificationType',
        'contactType',
        'relationship',
        'populationZone',
        'consultStatus',
        'caseType',
        'caseTrackingType',
        'documentType',
        'citiesList',
        'statesList',
        'followType',
        'originClass',
        'consultContactType',
        'contactDiagnosis',
        'patientStatus',
      ],
      messageDefault: 'SELECT_ONE',
    })

    const dataStore = await this.$store.getters['dataList/getDataList']({
      listState: [
        'company',
        'services',
        'headquarters',
        'consultType',
        'scholarship',
        'civilStatus',
        'patientType',
        'workShift',
        'employment',
        'jobArea',
        'accidentPlace',
        'accidentType',
        'lesionDepth',
        'skinCondition',
        'bloodAmount',
        'bodyPart',
        'injuryAgent',
        'injuryTime',
        'compromisedLiquid',
        'ecpPurpose',
        'booleanNA',
        'boolean',
        'booleanDN',
        'booleanDNNA',
        'booleanDNNAU',
        'otherBackground',
        'doseQuantity',
        'appliedScheme',
        'serviceType',
        'antiretroviral',
        'medicalFollowVIH',
        'medicalFollowVHC',
        'medicalFollowSYP',
        'vaccineHepatitisB',
        'medicalFollow',
        'severity',
        'caseStatus',
        'biologicalDanger',
        'antobodiesStatus',
        'destinationOrder',
        'originClassif',
        'principalDiagType',
      ],
    })

    this.steps = this.steps_hepa
    this.$root.$on('save-element', () => {
      _self._nextClicked()
    })
    this.$root.$on('save-edition-time', async (edition_user_id, meet_uuid) => {
      if (_self.meetUuid === meet_uuid) {
        _self.saveEditionTime(edition_user_id)
      }
    })
    this.$root.$on('change-case-type', async (case_type_id, not_attended) => {
      _self.load_stepper(case_type_id, not_attended)
    })
    this.$root.$on('fetch-medicines', async (medicineData) => {
      _self.medicinesList = await _self.fetch_medicines(medicineData)
    })
    this.$root.$on('fetch-cities', (department_id) => {
      _self.contactCityList = dataStore_Select.citiesList.filter(
        ({ state }) => !state || (state && state.id === department_id)
      )
    })
    this.$root.$on('fetch-company-cities', (department_id) => {
      _self.companyCityList = dataStore_Select.citiesList.filter(
        ({ state }) => !state || (state && state.id === department_id)
      )
    })
    this.$root.$on('fetch-clinical-cities', (department_id) => {
      _self.clinicalLaboratoryCityList = department_id
        ? dataStore_Select.citiesList.filter(
            ({ state }) => !state || (state && state.id === department_id)
          )
        : []
    })
    this.$root.$on('fetch-vaccine-cities', (department_id) => {
      _self.vaccineCityList = dataStore_Select.citiesList.filter(
        ({ state }) => !state || (state && state.id === department_id)
      )
    })
    this.$root.$on('fetch-clinical-laboratory-headquarters', (company_id) => {
      _self.clinicalHeadquarterList = company_id
        ? dataStore.headquarters.filter((hq) => hq.company_id == company_id)
        : []
      _self.requiredHeadquarter = _self.clinicalHeadquarterList.length !== 0
    })
    this.$root.$on(
      'filter-clinical-headquarters-by-department',
      (company_id, department_id) => {
        _self.clinicalHeadquarterList = company_id
          ? dataStore.headquarters.filter(
              (hq) =>
                hq.company_id == company_id &&
                hq.department_id === department_id
            )
          : []
      }
    )
    this.$root.$on(
      'filter-clinical-headquarters-by-city',
      (company_id, city_id) => {
        _self.clinicalHeadquarterList = company_id
          ? dataStore.headquarters.filter(
              (hq) => hq.company_id == company_id && hq.city_id === city_id
            )
          : []
      }
    )
    this.$root.$on('fetch-vaccine-headquarters', (company_id) => {
      _self.vaccineHeadquarterList = company_id
        ? dataStore.headquarters.filter((hq) => hq.company_id == company_id)
        : []
      _self.requiredVaccineHeadquarter =
        _self.vaccineHeadquarterList.length !== 0
    })
    this.$root.$on(
      'filter-vaccine-headquarters-by-department',
      (company_id, department_id) => {
        _self.vaccineHeadquarterList = company_id
          ? dataStore.headquarters.filter(
              (hq) =>
                hq.company_id == company_id &&
                hq.department_id === department_id
            )
          : []
      }
    )
    this.$root.$on(
      'filter-vaccine-headquarters-by-city',
      (company_id, city_id) => {
        _self.vaccineHeadquarterList = company_id
          ? dataStore.headquarters.filter(
              (hq) => hq.company_id == company_id && hq.city_id === city_id
            )
          : []
      }
    )
    // Register module
    if (!store.hasModule(this.ELEMENT_APP_STORE_MODULE_NAME)) {
      store.registerModule(
        this.ELEMENT_APP_STORE_MODULE_NAME,
        elementStoreModule
      )
    }

    _self.certificationTypeList = dataStore_Select.certificationType
    _self.consultTypeList = dataStore.consultType

    this.uuid = this.meetUuid
      ? this.meetUuid
      : router.currentRoute.params.case_uuid

    await this.fetchElement(this.uuid)
    _self.caseDate = await _self.fetchCaseDate(_self.uuid)
    _self.doctorList = await _self.fetchUsers()
    _self.editionTime = await _self.fetchEditionTime()

    _self.contactTypeList = dataStore_Select.contactType
    _self.relationshipList = dataStore_Select.relationship
    _self.populationZoneList = dataStore_Select.populationZone
    _self.consultStatusList = dataStore_Select.consultStatus
    _self.consultCaseTypeList = dataStore_Select.caseType
    _self.caseTrackingType = dataStore_Select.caseTrackingType
    _self.documentTypeList = dataStore_Select.documentType
    _self.contactDepartmentList = dataStore_Select.statesList
    _self.vaccineDepartmentList = dataStore_Select.statesList
    _self.clinicalLaboratoryDepartmentList = dataStore_Select.statesList

    _self.companyDepartmentList = dataStore_Select.statesList
    _self.companyCityList = dataStore_Select.citiesList.filter(
      ({ state }) =>
        !state ||
        (state && state.id === _self.elementData.company.department_id)
    )

    _self.originClassificationList = dataStore_Select.originClass
    _self.consultContactTypeList = dataStore_Select.consultContactType
    _self.contactDiagnosisList = dataStore_Select.contactDiagnosis
    _self.patientStatusList = dataStore_Select.patientStatus

    _self.principalDiagnosisTypeList = dataStore.principalDiagType
    _self.patientScholarshipList = dataStore.scholarship
    _self.patientCivilStatusList = dataStore.civilStatus
    _self.patientTypeList = dataStore.patientType
    _self.workShiftList = dataStore.workShift
    _self.jobHeldList = dataStore.employment
    _self.jobAreaList = dataStore.jobArea
    _self.accidentPlaceList = _self.dataOther(dataStore.accidentPlace)
    _self.accidentTypeList = dataStore.accidentType
    _self.lesionDepthList = dataStore.lesionDepth
    _self.skinConditionList = dataStore.skinCondition
    _self.bloodAmountList = dataStore.bloodAmount
    _self.bodyPartList = dataStore.bodyPart
    _self.injuryAgentList = dataStore.injuryAgent
    _self.injuryTimeList = _self.dataOther(dataStore.injuryTime)
    _self.compromisedLiquidList = _self.dataOther(dataStore.compromisedLiquid)
    _self.ecpPurposeList = _self.dataOther(dataStore.ecpPurpose)
    _self.booleanNAList = dataStore.booleanNA
    _self.booleanList = dataStore.boolean
    _self.booleanDnList = dataStore.booleanDN
    _self.booleanDnnaList = dataStore.booleanDNNA
    _self.booleanDnnauList = dataStore.booleanDNNAU
    _self.otherBackgroundList = _self.dataOther(dataStore.otherBackground)
    _self.doseQuantityList = dataStore.doseQuantity
    _self.appliedSchemeList = _self.dataOther(dataStore.appliedScheme)
    _self.serviceTypeList = dataStore.serviceType
    _self.antiretroviralList = dataStore.antiretroviral
    _self.medicalFollowVihList = dataStore.medicalFollowVIH
    _self.medicalFollowVhcList = dataStore.medicalFollowVHC
    _self.medicalFollowSyphilisList = dataStore.medicalFollowSYP
    _self.vaccineHepatitisBList = dataStore.vaccineHepatitisB
    _self.medicalFollowList = dataStore.medicalFollow
    _self.severityList = dataStore.severity
    _self.consultCaseStatusList = dataStore.caseStatus.filter(
      (element) => element.key === 'CLOSED' || element.key === 'FOLLOW'
    )
    _self.biologicalDangerList = dataStore.biologicalDanger
    _self.antobodiesStatusList = dataStore.antobodiesStatus
    _self.destinationOrdersList = dataStore.destinationOrder
    _self.originClassificationPatList = dataStore.originClassif

    const internalCompanies = dataStore.company
    const internalServices = dataStore.services.map((services) => ({
      ...services,
      value: `${services.cups} ${services.description}`,
    }))

    // Filter clinical laboratory by supplier_type_value
    _self.rootMedicinesList = await _self.fetch_medicines_complete()

    // Filter clinical laboratory by supplier_service_type
    _self.clinicalLaboratoryList = internalCompanies.filter(
      (element) => element.supplier_type_key === 'LABORATORY'
    )
    // Filter vaccine service by supplier_service_type
    _self.vaccineLaboratoryList = internalCompanies.filter(
      (element) => element.supplier_type_key === 'VACCINE'
    )
    // Filter vaccine laboratory by service_type
    _self.clinicalLaboratoryServicesList = internalServices.filter(
      (element) => element.service_type.key === 'LABORATORIES'
    )
    // Filter vaccine service by service_type_value
    _self.vaccineServicesList = internalServices.filter(
      (element) => element.service_type.key === 'VACCINATION'
    )
    _self.medicalConsultServicesList = internalServices.filter(
      (element) => element.service_type.key === 'FOLLOWUPS'
    )

    this.elementData.follow_type_id = this.caseTrackingType.find(
      (element) => element.key === 'INITIAL_VALORATION'
    ).id

    this.submitting = false

    if (this.elementData.consult_status_id) {
      this.currentConsultStatus = this.consultStatusList.find(
        (element) => this.elementData.consult_status_id === element.id
      ).key
    }

    let not_attended = false
    if (
      this.currentConsultStatus === '' ||
      this.currentConsultStatus !== 'ATTENDED'
    ) {
      not_attended = true
    }
    this.load_stepper(this.elementData.case_type_id, not_attended)

    this.disabled =
      this.edition_disabled ||
      (this.elementData.is_last_page &&
        !['NOT_ATTENDED', 'RESCHEDULE'].includes(this.currentConsultStatus))

    if (!this.elementData.follow_date_1) {
      this.elementData.follow_date_1 = null
    }
    if (!this.elementData.follow_date_2) {
      this.elementData.follow_date_2 = null
    }
    if (!this.elementData.follow_date_3) {
      this.elementData.follow_date_3 = null
    }
    if (!this.elementData.follow_date_4) {
      this.elementData.follow_date_4 = null
    }

    if (!this.elementData.services) {
      this.elementData.services = []
    }

    if (!this.elementData.orders) {
      this.elementData.orders = []
    }

    if (!this.elementData.secondary_diagnosis_id) {
      this.elementData.secondary_diagnosis_id = null
    }
    if (!this.elementData.main_diagnosis_id) {
      this.elementData.main_diagnosis_id = null
    }
  },
  unmounted() {
    // UnRegister on leave
    if (store.hasModule(this.ELEMENT_APP_STORE_MODULE_NAME))
      store.unregisterModule(this.ELEMENT_APP_STORE_MODULE_NAME)
  },
  methods: {
    dataOther(data) {
      const self = this
      const other = data.filter(({ key }) =>
        key.toLowerCase().startsWith('other')
      )
      if (other) {
        other.forEach((it) => {
          it.has_other = true
          it.other_field = `other_${it.key.toLowerCase()}_${it.id}`
          it.other_label = this.$t(
            `${self.RESOURCES_ELEMENT_NAME}.object.which`
          )
        })
      }
      return data
    },
    async fetchEditionTime() {
      return store
        .dispatch(`${this.ELEMENT_APP_STORE_MODULE_NAME}/fetchEditionTime`, {
          id: 1,
        })
        .then((response) => {
          console.log(response)
          return response.data
        })
        .catch((error) => undefined)
    },
    async fetchUsers() {
      return store
        .dispatch(`${this.ELEMENT_APP_STORE_MODULE_NAME}/fetchUsers`, {
          perPage: 10000,
          page: 1,
          query: '',
        })
        .then((response) =>
          response.data.items.map((element) => ({
            id: element.id,
            value: `[${element.username}] ${element.firt_name} ${element.surname_1}`,
            job_title_value: element.job_title_value,
          }))
        )
        .catch(() => {})
    },
    async fetchAvatar(avatar_id) {
      return store
        .dispatch(`${this.ELEMENT_APP_STORE_MODULE_NAME}/fetchImage`, {
          id: avatar_id,
        })
        .then((response) => response.data.img)
        .catch((error) => {
          if (error.response.status === 404) {
            console.info('404', this.elementData.patient.avatar)
            return undefined
          }
        })
    },
    async fetchCaseDate(meet_uuid) {
      return store
        .dispatch(`${this.ELEMENT_APP_STORE_MODULE_NAME}/fetch_case_date`, {
          meet_uuid,
        })
        .then(async (response) => response.data)
        .catch((error) => {
          if (error.response.status === 404) {
            return undefined
          }
        })
    },
    getFinishButtonLabel() {
      return this.not_attended ? 'Guardar' : 'Finalizar'
    },
    load_stepper(case_type_id, not_attended) {
      this.not_attended = !!not_attended
      if (not_attended) {
        this.steps = this.steps_not_attended
      } else {
        const type = this.consultCaseTypeList.find(
          (element) => element.id == case_type_id
        ).key
        this.case_type = type
        const wizardBody = document.querySelector('.wizard__body')
        const wizardArrow = document.querySelector('.wizard__arrow')
        switch (type) {
          case 'HEPA':
            this.steps = this.steps_hepa
            wizardBody.style.backgroundColor = '#fff'
            wizardArrow.style.backgroundColor = '#fff'
            break
          case 'COVID':
            this.steps = this.steps_covid
            wizardBody.style.backgroundColor = '#A3E4D7'
            wizardArrow.style.backgroundColor = '#A3E4D7'
            break
          case 'OTHER':
            this.steps = this.steps_other
            wizardBody.style.backgroundColor = '#F9E79F'
            wizardArrow.style.backgroundColor = '#F9E79F'
            break
        }
      }
      this.$forceUpdate()
    },
    async fetch_medicines(medicineData) {
      return store
        .dispatch(`${this.ELEMENT_APP_STORE_MODULE_NAME}/fetch_medicines`, {
          queryParams: {
            perPage: 10000,
            page: 1,
            query: medicineData,
          },
        })
        .then((response) => {
          const data = response.data.items
          for (const it of data) {
            it.status = 1
          }
          return data
        })
        .catch(() => [])
    },
    async fetch_medicines_complete() {
      return store
        .dispatch(
          `${this.ELEMENT_APP_STORE_MODULE_NAME}/fetch_medicines_complete`
        )
        .then((response) => response.data)
        .catch(() => [])
    },
    setData(element, element_field, data) {
      if (!element[element_field]) {
        element[element_field] = data
      }
    },
    async fetchElement(meet_uuid) {
      this.newElement = false
      const _self = this
      return store
        .dispatch(`${this.ELEMENT_APP_STORE_MODULE_NAME}/fetch_element`, {
          meet_uuid,
        })
        .then(async (response) => {
          _self.elementData = response.data
          if (
            !_self.elementData.is_last_page &&
            !_self.elementData.consult_date
            
          ) {
            let localDate = new Date().toLocaleString('es-CO', { timeZone: 'America/Bogota', hour12: false });
            _self.elementData.consult_date = localDate
          }
          if (
            !_self.elementData.company.department_id &&
            _self.elementData.department_id
          ) {
            _self.elementData.company.department_id =
              _self.elementData.department_id
          }
          if (!_self.elementData.company.city_id && _self.elementData.city_id) {
            _self.elementData.company.city_id = _self.elementData.city_id
          }
          _self.setData(_self.elementData, 'last_dose_date', '')
          _self.setData(_self.elementData, 'fum_date', '')
          _self.setData(_self.elementData, 'last_dose_date_none', '0')
          _self.setData(_self.elementData.patient, 'patient_type_id', '')
          _self.setData(
            _self.elementData,
            'consult_type_id',
            _self.consultTypeList.find((element) => element.key == 'VIRTUAL').id
          )
          _self.setData(_self.elementData, 'orders', [])
          _self.setData(_self.elementData, 'services', [])
          _self.setData(_self.elementData, 'patient_positive_hiv', '0')
          _self.setData(_self.elementData, 'patient_positive_vhc', '0')
          _self.setData(_self.elementData, 'patient_positive_vhb', '0')
          _self.setData(_self.elementData, 'patient_positive_syphilis', '0')
          _self.setData(_self.elementData, 'certifications', [
            {
              id: Math.floor(Math.random() * 100) * -1,
              status: 1,
              certification_type_id: _self.certificationTypeList[1].id,
              from_date: '',
              to_date: '',
            },
          ])
          _self.current_certification = _self.elementData.certifications[0]

          if (this.elementData.patient.avatar_id) {
            this.elementData.patient.avatar = null
          }
        })
        .catch((error) => {
          console.log(error)
          if (error.response.status === 404) {
            _self.elementData = undefined
          }
        })
    },
    nextClicked(currentPage) {
      this.currentPage = currentPage
      if (this.not_attended) {
        this.is_last_page = true
      } else {
        switch (this.case_type) {
          case 'HEPA':
            this.is_last_page = this.steps_hepa[currentPage].final
            this.is_action_plan =
              this.steps_hepa[currentPage].slot == 'page6' &&
              this.elementData.consult_case_status_key == 'CLOSED'
            break
          case 'COVID':
            this.is_last_page = this.steps_covid[currentPage].final
            break
          case 'OTHER':
            this.is_last_page = this.steps_other[currentPage].final
            break
        }
      }
      this.submitting = true
      this._validateForm()
      return false
    },
    async _validateForm() {
      const _self = this
      let elementFormRef = ''
      let medicalOrder = false
      switch (_self.case_type) {
        case 'HEPA':
          elementFormRef = this.steps_hepa[this.currentPage].slot
          medicalOrder = this.steps_hepa[this.currentPage].medicalOrder
          break
        case 'COVID':
          elementFormRef = this.steps_covid[this.currentPage].slot
          medicalOrder = this.steps_hepa[this.currentPage].medicalOrder
          break
        case 'OTHER':
          elementFormRef = this.steps_other[this.currentPage].slot
          medicalOrder = this.steps_hepa[this.currentPage].medicalOrder
          break
      }
      let success =
        this.not_attended ||
        (await _self.$refs[elementFormRef].$refs.elementForm.validate())
      if (success && medicalOrder) {
        success = this._validateNextConsult()
      }
      if (success) {
        if (this.is_last_page) {
          this.elementData.is_last_page = true
          this.$root.$emit('bv::show::modal', 'modal-save-confirmation')
          this.submitting = false
        } else {
          this.elementData.is_last_page = false
          if (this.is_action_plan && !this.disabled) {
            this.$root.$emit('bv::show::modal', 'modal-plan-confirmation')
            this.submitting = false
          } else {
            this._nextClicked()
          }
        }
      } else {
        this.submitting = false
      }
    },
    _validateNextConsult() {
      if (this.elementData.consult_case_status_key === 'FOLLOW') {
        if (
          this.elementData.orders.find(
            (element) => element.service_type.key === 'FOLLOWUPS'
          )
        ) {
          return true
        }
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'No puede continuar',
            icon: 'SaveIcon',
            variant: 'danger',
            text: 'Se requieren definir las características de la próxima consulta',
          },
        })
        return false
      }
      return true
    },
    _nextClicked() {
      if (this.disabled) {
        this.currentPage += 1
        this.$refs.wizard.goNext(true)
        this.submitting = false
      } else {
        this.saveForm(this.currentPage)
      }
    },
    async saveEditionTime(edition_user_id) {
      const method = `${this.ELEMENT_APP_STORE_MODULE_NAME}/save_edition_time`
      const _self = this
      return store
        .dispatch(method, { edition_user_id, meet_uuid: _self.uuid })
        .then((response) => {
          _self.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: _self.$t(
                `${_self.RESOURCES_ELEMENT_NAME}.action_results.update.title`
              ),
              icon: 'SaveIcon',
              variant: 'success',
              text: _self.$t(
                `${_self.RESOURCES_ELEMENT_NAME}.action_results.update.success`
              ),
            },
          })
          return true
        })
        .catch((error) => {
          _self.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: _self.$t(
                `${_self.RESOURCES_ELEMENT_NAME}.action_results.update.title`
              ),
              icon: 'SaveIcon',
              variant: 'danger',
              text: _self.$t(
                `${_self.RESOURCES_ELEMENT_NAME}.action_results.update.failure`
              ),
            },
          })
          return false
        })
    },
    async save() {
      if (this.meetUuid) {
        return true
      }
      const method = `${this.ELEMENT_APP_STORE_MODULE_NAME}/save_element`
      const _self = this
      _self.elementData.case_date = _self.caseDate
      if (_self.caseDate.start_date instanceof moment) {
        _self.elementData.case_date.start_date =
          _self.caseDate.start_date.format('YYYY-MM-DDTHH:mm:ss')
        _self.elementData.case_date.end_date = _self.caseDate.end_date.format(
          'YYYY-MM-DDTHH:mm:ss'
        )
        _self.elementData.case_date.rescheduled = true
      }

      if (_self.elementData.source_birthdate === '') {
        _self.elementData.source_birthdate = null
      }
      return store
        .dispatch(method, {
          elementData: _self.elementData,
          meet_uuid: _self.uuid,
        })
        .then((response) => {
          _self.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: _self.$t(
                `${_self.RESOURCES_ELEMENT_NAME}.action_results.update.title`
              ),
              icon: 'SaveIcon',
              variant: 'success',
              text: _self.$t(
                `${_self.RESOURCES_ELEMENT_NAME}.action_results.update.success`
              ),
            },
          })
          return true
        })
        .catch((error) => {
          _self.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: _self.$t(
                `${_self.RESOURCES_ELEMENT_NAME}.action_results.update.title`
              ),
              icon: 'SaveIcon',
              variant: 'danger',
              text: _self.$t(
                `${_self.RESOURCES_ELEMENT_NAME}.action_results.update.failure`
              ),
            },
          })
          return false
        })
    },
    async generate_document_consult_follow_plan() {
      const method = `${this.ELEMENT_APP_STORE_MODULE_NAME}/generate_final_document`
      const _self = this
      return store
        .dispatch(method, {
          meet_uuid: _self.uuid,
          template_type: 'CONSULT_FOLLOW_PLAN',
          notification_destination: 2,
          destination_folder: 'INITIAL',
        })
        .then((response) => {
          _self.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: _self.$t(
                `${_self.RESOURCES_ELEMENT_NAME}.action_results.document_consult_follow_plan.title`
              ),
              icon: 'SaveIcon',
              variant: 'success',
              text: _self.$t(
                `${_self.RESOURCES_ELEMENT_NAME}.action_results.document_consult_follow_plan.success`
              ),
            },
          })
          return true
        })
        .catch((error) => {
          _self.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: _self.$t(
                `${_self.RESOURCES_ELEMENT_NAME}.action_results.document_consult_follow_plan.title`
              ),
              icon: 'SaveIcon',
              variant: 'danger',
              text: _self.$t(
                `${_self.RESOURCES_ELEMENT_NAME}.action_results.document_consult_follow_plan.failure`
              ),
            },
          })
          return false
        })
    },
    async generate_document_close() {
      const method = `${this.ELEMENT_APP_STORE_MODULE_NAME}/generate_final_document`
      const _self = this
      return store
        .dispatch(method, {
          meet_uuid: _self.uuid,
          template_type: 'CONSULT_FINAL_CLOSE',
          notification_destination: 2,
          destination_folder: 'INITIAL',
        })
        .then((response) => {
          _self.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: _self.$t(
                `${_self.RESOURCES_ELEMENT_NAME}.action_results.document_close.title`
              ),
              icon: 'SaveIcon',
              variant: 'success',
              text: _self.$t(
                `${_self.RESOURCES_ELEMENT_NAME}.action_results.document_close.success`
              ),
            },
          })
          return true
        })
        .catch((error) => {
          _self.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: _self.$t(
                `${_self.RESOURCES_ELEMENT_NAME}.action_results.document_close.title`
              ),
              icon: 'SaveIcon',
              variant: 'danger',
              text: _self.$t(
                `${_self.RESOURCES_ELEMENT_NAME}.action_results.document_close.failure`
              ),
            },
          })
          return false
        })
    },
    async generate_document_certification() {
      const method = `${this.ELEMENT_APP_STORE_MODULE_NAME}/generate_final_document`
      const _self = this
      return store
        .dispatch(method, {
          meet_uuid: _self.uuid,
          template_type: 'DISABILITY',
          notification_destination: 1,
          destination_folder: 'INITIAL',
        })
        .then((response) => {
          _self.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: _self.$t(
                `${_self.RESOURCES_ELEMENT_NAME}.action_results.document_certification.title`
              ),
              icon: 'SaveIcon',
              variant: 'success',
              text: _self.$t(
                `${_self.RESOURCES_ELEMENT_NAME}.action_results.document_certification.success`
              ),
            },
          })
          return true
        })
        .catch((error) => {
          _self.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: _self.$t(
                `${_self.RESOURCES_ELEMENT_NAME}.action_results.document_certification.title`
              ),
              icon: 'SaveIcon',
              variant: 'danger',
              text: _self.$t(
                `${_self.RESOURCES_ELEMENT_NAME}.action_results.document_certification.failure`
              ),
            },
          })
          return false
        })
    },
    async generate_document_medical_orders() {
      const method = `${this.ELEMENT_APP_STORE_MODULE_NAME}/generate_final_document`
      const _self = this
      return store
        .dispatch(method, {
          meet_uuid: _self.uuid,
          template_type: 'MEDICAL_ORDER',
          notification_destination: 1,
          destination_folder: 'INITIAL',
        })
        .then((response) => {
          _self.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: _self.$t(
                `${_self.RESOURCES_ELEMENT_NAME}.action_results.document_medical_order.title`
              ),
              icon: 'SaveIcon',
              variant: 'success',
              text: _self.$t(
                `${_self.RESOURCES_ELEMENT_NAME}.action_results.document_medical_order.success`
              ),
            },
          })
          return true
        })
        .catch((error) => {
          _self.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: _self.$t(
                `${_self.RESOURCES_ELEMENT_NAME}.action_results.document_medical_order.title`
              ),
              icon: 'SaveIcon',
              variant: 'danger',
              text: _self.$t(
                `${_self.RESOURCES_ELEMENT_NAME}.action_results.document_medical_order.failure`
              ),
            },
          })
          return false
        })
    },
    async generate_document_followups_orders() {
      const method = `${this.ELEMENT_APP_STORE_MODULE_NAME}/generate_final_document`
      const _self = this
      return store
        .dispatch(method, {
          meet_uuid: _self.uuid,
          template_type: 'FOLLOWUPS_ORDER',
          notification_destination: 1,
          destination_folder: 'INITIAL',
        })
        .then((response) => {
          _self.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: _self.$t(
                `${_self.RESOURCES_ELEMENT_NAME}.action_results.document_followups_order.title`
              ),
              icon: 'SaveIcon',
              variant: 'success',
              text: _self.$t(
                `${_self.RESOURCES_ELEMENT_NAME}.action_results.document_followups_order.success`
              ),
            },
          })
          return true
        })
        .catch((error) => {
          _self.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: _self.$t(
                `${_self.RESOURCES_ELEMENT_NAME}.action_results.document_medical_order.title`
              ),
              icon: 'SaveIcon',
              variant: 'danger',
              text: _self.$t(
                `${_self.RESOURCES_ELEMENT_NAME}.action_results.document_medical_order.failure`
              ),
            },
          })
          return false
        })
    },

    async saveForm() {
      this.submitting = true
      this.process_additional_data()
      const _self = this
      if (await _self.save()) {
        if (this.is_last_page) {
          if (!this.not_attended) {
            await this.handleDocumentGeneration()
          }
          _self.$router.replace({ name: 'apps-schedules' })
        } else {
          await _self.fetchElement(_self.uuid)
          _self.currentPage += 1
          _self.$refs.wizard.goNext(true)
        }
      }
      this.submitting = false
    },

    async handleDocumentGeneration() {
      const newMedicalOrders = this.elementData.orders.filter(
        (order) => order.isNew && order.service_type.key === 'MEDICINES'
      )
      const newFollowupsOrders = this.elementData.orders.filter(
        (order) => order.isNew && order.service_type.key === 'INTERCONSULT'
      )
      const newFollowConsult = this.elementData.orders.filter(
        (order) => order.isNew && order.service_type.key === 'FOLLOWUPS'
      )
      if (this.elementData.requires_follow && newFollowConsult.length > 0) {
        await this.generate_document_consult_follow_plan()
      } else if (
        !this.elementData.requires_follow &&
        !this.elementData.document_closed
      ) {
        await this.generate_document_close()
      }
      if (this.elementData.requires_certification) {
        await this.generate_document_certification()
      }
      if (
        this.elementData.has_new_medical_orders &&
        newMedicalOrders.length > 0
      ) {
        await this.generate_document_medical_orders()
      }
      if (
        this.elementData.has_new_followups_orders &&
        newFollowupsOrders.length > 0
      ) {
        await this.generate_document_followups_orders()
      }
    },

    process_additional_data() {
      if (
        this.elementData.medical_follow_id &&
        !this.elementData.requires_follow
      ) {
        const { id } = this.medicalFollowList.find(
          (element) => element.key == 'REQUIRES'
        )
        this.elementData.requires_follow =
          id == this.elementData.medical_follow_id
      }
      if (this.elementData.consult_case_status_id) {
        this.elementData.requires_follow =
          this.consultCaseStatusList.find(
            (element) => element.id == this.elementData.consult_case_status_id
          )?.key === 'FOLLOW'
      }
      if (this.elementData.certifications[0].requires_certifications_id) {
        const id_cert = this.booleanList.find(
          (element) => element.key == 'YES'
        ).id
        this.elementData.requires_certification =
          id_cert ==
          this.elementData.certifications[0].requires_certifications_id
      }
      if (this.elementData.orders) {
        this.elementData.has_new_medical_orders = this.elementData.orders.some(
          (order) => order.isNew && order.service_type.key === 'MEDICINES'
        )
        console.log(
          'medicinees Orders: ',
          this.elementData.has_new_medical_orders
        )
      }
      if (this.elementData.orders) {
        this.elementData.has_new_followups_orders =
          this.elementData.orders.some(
            (order) => order.isNew && order.service_type.key === 'INTERCONSULT'
          )
        console.log(
          'interconsult Orders: ',
          this.elementData.has_new_followups_orders
        )
      }
      if (this.elementData.document_closed === undefined) {
        this.elementData.document_closed = false
        console.log('check_doc_closed: ', this.elementData.document_closed)
      }
      this.elementData.orders.forEach((order) => {
        if (order.vaccineNew === true && this.elementData.is_last_page) {
          order.vaccineNew = false
        }
      })
      if (this.elementData.is_last_page && !this.elementData.final_consult_date && this.elementData.consult_status_id === 1553) {
        let localDate = new Date().toLocaleString('es-CO', { timeZone: 'America/Bogota', hour12: false });
        this.elementData.final_consult_date = localDate
        console.log('final_consult_date: ', this.elementData.final_consult_date)
      }
    },
    backClicked(currentPage) {
      console.log('back clicked', currentPage)

      this.currentPage -= 1
      return true // return false if you want to prevent moving to previous page
    },
  },
}
</script>
<style>
.float {
  position: fixed;
  top: 15px;
  right: 5px;
  background-color: grey;
  padding: 10px;
  border-radius: 5px;
}

.custom-select:disabled {
  color: #42474c;
  opacity: 1;
}
</style>
